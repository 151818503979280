import { useDispatch, useSelector } from 'react-redux'
import { ClientsStyled } from './Clients.style'
import { State } from 'reducers'
import { useEffect } from 'react'
import { getClients } from 'pages/Projects/Projects.actions'

export const Clients = () => {
  const dispatch = useDispatch()
  const clients = useSelector((state: State) => state.projects.clients)

  useEffect(() => {
    dispatch(getClients())
  }, [dispatch])

  return (
    <ClientsStyled>
      <h1>All clients</h1>
      <div>
        To add or edit clients, please go to{' '}
        <a
          href="https://www.notion.so/smart-chain/6981eb1442924214870b5e7135e38928?v=ee5cfcb255064ad19c7a6e5497e2ed20"
          target="_blank"
          rel="noreferrer"
        >
          🌐 <u>Clients / Partenaires / Prospects</u>
        </a>
      </div>
      <br />
      <div>
        {clients.map((client) => (
          <div>{`${client.emoji ?? ''} ${client.name}`}</div>
        ))}
      </div>
    </ClientsStyled>
  )
}
