import { Link, useNavigate } from 'react-router-dom'

// prettier-ignore
import { MenuBar, MenuLogo, MenuCenter, MenuGoogleContainer, MenuStyled } from './Menu.style'
import { Button } from '../Button/Button.view'

export const MenuView = () => {
  const navigate = useNavigate()

  return (
    <MenuStyled>
      <MenuBar>
        <Link to="/calendar">
          <MenuLogo alt="Smartpod" src="/logo.svg" />
        </Link>
        <div />
        <MenuCenter>
          <Link to="/calendar">My Calendar</Link>
          <Link to="/user">My Profile</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/clients">Clients</Link>
        </MenuCenter>
        <div />
        <MenuGoogleContainer>
          <Button
            appearance="primary"
            clickCallback={() => {
              localStorage.removeItem('jwt')
              navigate('/')
            }}
          >
            Logout
          </Button>
        </MenuGoogleContainer>
      </MenuBar>
    </MenuStyled>
  )
}
