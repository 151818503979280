import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR } from 'app/App.components/Toaster/Toaster.constants'
import axios from 'axios'
import { GetProjectsOutputs } from 'shared/project/GetProjects'
import { AddTimeInputs, AddTimeOutputs } from 'shared/time/AddTime'
import { State } from 'reducers'
import { GetClientsOutputs } from 'shared/client/GetClients'
import { GetMyTimesInputs, GetMyTimesOutputs } from 'shared/time/GetMyTimes'

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST'
export const GET_PROJECTS_RESULT = 'GET_PROJECTS_RESULT'
export const GET_PROJECTS_ERROR = 'GET_PROJECTS_ERROR'
export const getProjects = () => async (dispatch: any) => {
  dispatch({
    type: GET_PROJECTS_REQUEST,
  })

  try {
    const getProjectsOutputs: GetProjectsOutputs = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/project/get-projects`)).data

    dispatch({
      type: GET_PROJECTS_RESULT,
      projects: getProjectsOutputs.projects,
    })
  } catch (error: any) {
    console.error(error)
    dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
    dispatch({
      type: GET_PROJECTS_ERROR,
    })
  }
}

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST'
export const GET_CLIENTS_RESULT = 'GET_CLIENTS_RESULT'
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR'
export const getClients = () => async (dispatch: any) => {
  dispatch({
    type: GET_CLIENTS_REQUEST,
  })

  try {
    const getClientsOutputs: GetClientsOutputs = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/client/get-clients`)).data

    dispatch({
      type: GET_CLIENTS_RESULT,
      clients: getClientsOutputs.clients,
    })
  } catch (error: any) {
    console.error(error)
    dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
    dispatch({
      type: GET_CLIENTS_ERROR,
    })
  }
}

export const ADD_TIME_REQ_NO_LOADER = 'ADD_TIME_REQ_NO_LOADER'
export const ADD_TIME_RESULT = 'ADD_TIME_RESULT'
export const ADD_TIME_ERROR = 'ADD_TIME_ERROR'
export const addTime =
  ({ projectId, date, hours }: AddTimeInputs) =>
  async (dispatch: any, getState: any) => {
    const state: State = getState()

    dispatch({
      type: ADD_TIME_REQ_NO_LOADER,
    })

    try {
      const addTimeAnswer: AddTimeOutputs = (
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/time/add-time`,
          { projectId, date, hours },
          { headers: { Authorization: `Bearer ${state.auth.jwt}` } },
        )
      ).data

      dispatch({
        type: ADD_TIME_RESULT,
      })
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: ADD_TIME_ERROR,
      })
    }
  }

export const GET_MY_TIMES_REQUEST = 'GET_MY_TIMES_REQUEST'
export const GET_MY_TIMES_RESULT = 'GET_MY_TIMES_RESULT'
export const GET_MY_TIMES_ERROR = 'GET_MY_TIMES_ERROR'
export const getMyTimes =
  ({ date }: GetMyTimesInputs) =>
  async (dispatch: any, getState: any) => {
    const state: State = getState()

    dispatch({
      type: GET_MY_TIMES_REQUEST,
    })

    try {
      const myTimes: GetMyTimesOutputs = (
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/time/get-my-times`,
          { date },
          { headers: { Authorization: `Bearer ${state.auth.jwt}` } },
        )
      ).data

      dispatch({
        type: GET_MY_TIMES_RESULT,
        myTimes: myTimes.times,
      })
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: GET_MY_TIMES_ERROR,
      })
    }
  }
