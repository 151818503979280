import styled, { css } from 'styled-components/macro'
import { backgroundColor, containerColor, FullPage, primaryColor } from 'styles'

export const CalendarStyled = styled(FullPage)``

export const CalendarHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-bottom: 50px;
`

export const CalendarHeaderUser = styled.div``

export const CalendarHeaderMonth = styled.div`
  text-align: center;
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-gap: 20px;

  > h1 {
    line-height: 50px;
  }
`

export const CalendarHeaderMonthArrow = styled.div<{ inverse?: boolean }>`
  background-color: ${containerColor};
  padding: 13px;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  cursor: pointer;

  ${(props) =>
    props.inverse &&
    css`
      > img {
        transform: scaleX(-1);
      }
    `}
`

export const CalendarHeaderProject = styled.div``

export const CalendarGrid = styled.div`
  background-color: ${containerColor};
  padding: 10px;
  border-radius: 10px;
`

export const CalendarRow = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: ${(props) => `6fr repeat(${props.columns - 1}, 1fr)`};
  grid-gap: 0;
  height: 40px;
  line-height: 40px;

  /* &:nth-child(even) {
    background: #00000020;
  } */
  &:nth-child(odd) {
    background: #00000010;
  }
`

export const CalendarHeadRow = styled(CalendarRow)`
  //background-color: ${backgroundColor};
`

export const CalendarColumn = styled.div<{ isCurrentDay?: boolean; isIncorrect?: boolean; align?: string }>`
  text-align: ${(props) => props.align || 'center'};
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:nth-child(odd) {
    background: #00000010;
  }

  ${(props) =>
    props.isCurrentDay &&
    css`
      background: ${primaryColor} !important;
      border-radius: 5px;
      height: 50px;
    `}

  ${(props) =>
    props.isIncorrect &&
    css`
      color: red !important;
    `}

  > input {
    background-color: #00000000;
    border: none;
    width: 100%;
    height: 40px;
    text-align: center;
  }

  > input::-webkit-outer-spin-button,
  > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  > input[type='number'] {
    -moz-appearance: textfield;
  }
`

export const CalendarSelect = styled.select`
  color: white;
  background: ${containerColor};
  border: none;
  border-radius: 5px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  max-width: 100%;
`

export const CalendarLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const CalendarCatTitle = styled.div`
  color: #2a416a;
  font-weight: bold;
`
