import styled from 'styled-components/macro'

export const LoginStyled = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const LoginLogo = styled.img`
  display: block;
`

export const LoginGoogle = styled.div`
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
`
