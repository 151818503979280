import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR, SUCCESS } from 'app/App.components/Toaster/Toaster.constants'
import * as jsonwebtoken from 'jsonwebtoken'
import { JwtDecoded } from 'shared/user/JwtDecoded'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_RESULT = 'LOGIN_RESULT'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export type LoginObject = {
  credential: string
  clientId: string
  select_by: string
}

export type Login = {
  navigate: any
  loginObject: LoginObject
}

/* 
TODO: Implement actual login verify
https://developers.google.com/identity/gsi/web/guides/verify-google-id-token?authuser=5
*/

export const login =
  ({ navigate, loginObject }: Login) =>
  async (dispatch: any) => {
    dispatch({
      type: LOGIN_REQUEST,
    })

    try {
      const jwtDecoded: JwtDecoded = jsonwebtoken.decode(loginObject.credential) as JwtDecoded

      if (jwtDecoded.hd === 'smart-chain.fr') {
        dispatch(showToaster(SUCCESS, 'Login success', 'Welcome to the SmartPod'))
        dispatch({
          type: LOGIN_RESULT,
          jwt: loginObject.credential,
          email: jwtDecoded.email,
        })
        localStorage.setItem('jwt', loginObject.credential)
        navigate('/calendar')
      } else {
        dispatch(showToaster(ERROR, 'Error', 'You are not allowed to access this app'))
        dispatch({
          type: LOGIN_ERROR,
        })
      }
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: LOGIN_ERROR,
      })
    }
  }

export const LOGOUT = 'LOGOUT'
export const logout = () => (dispatch: any) => {
  dispatch({
    type: LOGOUT,
  })
}
