import styled from 'styled-components/macro'
import { backgroundColor, textColor } from 'styles'

export const MenuStyled = styled.div`
  font-size: 14px;
  position: fixed;
  width: 100%;
  top: 20px;
  z-index: 12;
`

export const MenuBar = styled.div`
  margin: 0 20px 0 20px;
  max-width: calc(100vw - 40px);
  padding: 0 13px;

  position: relative;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: grid;
  grid-template-columns: 225px auto auto auto 320px;
  gap: 10px;
  font-weight: 500;
  overflow: hidden;
  transition: height 1s ease-in-out;

  background: ${backgroundColor};
  border: 2px solid ${backgroundColor};
  box-sizing: border-box;
  box-shadow: -10px -10px 20px rgba(8, 21, 45, 0.4), 5px 5px 15px rgba(5, 12, 27, 0.6);
  border-radius: 10px;

  > a {
    margin: 13px 0;
  }
`

export const MenuGoogleContainer = styled.div`
  float: right;
  margin: 13px 0 13px auto;
`

export const MenuLogo = styled.img`
  height: 40px;
  margin: 5px 0px 5px 10px;
  display: block;
`

export const MenuCenter = styled.div`
  position: relative;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, auto);

  > a,
  > button {
    line-height: 80px;
    color: ${textColor};
    font-weight: 600;
    margin: 0 10px;
  }

  @media (max-width: 1279px) {
    grid-template-columns: repeat(3, auto);
  }

  @media (max-width: 1023px) {
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 40px);

    > div {
      display: block !important;
      line-height: 40px;
    }
  }
`

export const MenuLeft = styled.div`
  position: relative;
  text-align: center;
  height: 80px;
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);

  > button {
    margin: 13px 0 !important;
  }

  > a {
    text-decoration: capitalize;
    line-height: 80px;
    color: ${textColor};
    font-weight: 600;
    margin: 0 10px;

    > button {
      margin: 13px 0 !important;
    }
  }
`
