import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LOGIN_RESULT, LOGIN_ERROR } from 'pages/Login/Login.actions'
import { showToaster } from '../Toaster/Toaster.actions'
import { ERROR } from '../Toaster/Toaster.constants'
import * as jsonwebtoken from 'jsonwebtoken'
import { JwtDecoded } from 'shared/user/JwtDecoded'

export const ProtectedRoute = ({ children }: any) => {
  const localJwt = localStorage.getItem('jwt')
  const dispatch = useDispatch()

  if (localJwt) {
    try {
      const jwtDecoded: JwtDecoded = jsonwebtoken.decode(localJwt) as JwtDecoded

      if (jwtDecoded.hd === 'smart-chain.fr') {
        dispatch({
          type: LOGIN_RESULT,
          jwt: localJwt,
          email: jwtDecoded.email,
        })
        return children
      } else {
        dispatch(showToaster(ERROR, 'Error', 'You are not allowed to access this app'))
        dispatch({
          type: LOGIN_ERROR,
        })
        return <Navigate to="/" />
      }
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: LOGIN_ERROR,
      })
      return <Navigate to="/" />
    }
  }

  return <Navigate to="/" />
}
