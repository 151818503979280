import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'

//prettier-ignore
import { CalendarCatTitle, CalendarColumn, CalendarGrid, CalendarHeaderGrid, CalendarHeaderMonth, CalendarHeaderMonthArrow, CalendarHeaderProject, CalendarHeaderUser, CalendarHeadRow, CalendarLoader, CalendarRow, CalendarSelect, CalendarStyled } from './Calendar.style'
import { addTime, getMyTimes, getProjects } from '../Projects/Projects.actions'
import { Project } from 'shared/project/Project'
import { Time } from 'shared/time/Time'
import { ObjectId } from 'mongodb'

interface ProjectWithTimes extends Project {
  isShowing: boolean
  times: Time[]
}

export const Calendar = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state: State) => state.loading)
  const { projects, myTimes } = useSelector((state: State) => state.projects)

  const [boardDate, setBoardDate] = useState<dayjs.Dayjs>(dayjs())
  const [projectsShowing, setProjectsShowing] = useState<ProjectWithTimes[]>([])

  useEffect(() => {
    dispatch(getProjects())
    dispatch(getMyTimes({ date: new Date() }))
  }, [dispatch])

  useEffect(() => {
    const projectsShowing = projects.map((project) => ({
      ...project,
      isShowing: myTimes.filter((time) => time.projectId === project.notionId).length > 0,
      times: myTimes.filter((time) => time.projectId === project.notionId),
    }))
    setProjectsShowing(projectsShowing)
  }, [projects, myTimes])

  const daysInSelectedMonth = boardDate.daysInMonth()
  const datesInSelectedMonth = [...Array(daysInSelectedMonth).keys()]
    .map((day) => day + 1)
    .map((day) => dayjs(`${boardDate.format('YYYY-MM')}-${day}`))

  const setPreviousMonth = () => {
    setBoardDate(boardDate.add(-1, 'month'))
  }

  const setNextMonth = () => {
    setBoardDate(boardDate.add(1, 'month'))
  }

  const getTimeCallback = (projectId: string, cellDate: dayjs.Dayjs) => {
    const project = projectsShowing.filter((project) => project.notionId === projectId)?.[0]
    return project.times.filter((timeObject: any) => dayjs(timeObject.date).format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD'))?.[0]
      ?.hours
  }

  const addTimeCallback = (projectId: string, cellDate: dayjs.Dayjs, hours: number) => {
    projectsShowing
      .filter((project) => project.notionId === projectId)?.[0]
      ?.times.push({
        _id: new ObjectId(),
        email: '',
        projectId,
        date: new Date(cellDate.format('YYYY-MM-DD')),
        hours,
        createdAt: new Date(),
        updatedAt: new Date(),
      })
    setProjectsShowing([...projectsShowing])

    console.log({ projectId, hours, date: new Date(cellDate.format('YYYY-MM-DD')) })
    dispatch(addTime({ projectId, hours, date: new Date(cellDate.format('YYYY-MM-DD')) }))
  }

  const addProject = (projectId: string) => {
    projectsShowing.filter((project) => project.notionId === projectId)[0].isShowing = true
    setProjectsShowing([...projectsShowing])
  }

  const getTotal = (cellDate: dayjs.Dayjs) => {
    return projectsShowing
      .filter((project) => project.isShowing)
      .map(
        (project) =>
          project.times.filter((timeObject: any) => dayjs(timeObject.date).format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD'))?.[0]
            ?.hours,
      )
      .reduce((a, b) => (a | 0) + (b | 0), 0)
  }

  console.log(projectsShowing)

  return (
    <CalendarStyled>
      <CalendarHeaderGrid>
        <CalendarHeaderUser></CalendarHeaderUser>
        <CalendarHeaderMonth>
          <CalendarHeaderMonthArrow inverse onClick={() => setPreviousMonth()}>
            <img src="icons/arrow.svg" alt="arrow" />
          </CalendarHeaderMonthArrow>
          <h1>{`${dayjs(boardDate).format('MMMM')} ${dayjs(boardDate).get('year')}`}</h1>
          <CalendarHeaderMonthArrow onClick={() => setNextMonth()}>
            <img src="icons/arrow.svg" alt="arrow" />
          </CalendarHeaderMonthArrow>
        </CalendarHeaderMonth>
        <CalendarHeaderProject></CalendarHeaderProject>
      </CalendarHeaderGrid>
      {loading ? (
        <CalendarLoader>
          <img src="images/lightspeed.svg" alt="" />
        </CalendarLoader>
      ) : (
        <CalendarGrid>
          <CalendarHeadRow columns={datesInSelectedMonth.length + 1}>
            <CalendarColumn align="left">
              <CalendarSelect value="0" onChange={(e: any) => addProject(e.target.value)}>
                <option value="0">Add Project</option>
                {projectsShowing.map((project) => (
                  <option value={project.notionId} key={project.notionId}>
                    {`${project.emoji ?? ''} ${project.name}`}
                  </option>
                ))}
              </CalendarSelect>
            </CalendarColumn>
            {datesInSelectedMonth.map((cellDate) => (
              <CalendarColumn isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')} key={cellDate.format('DD')}>
                <b>{cellDate.format('DD')}</b>
              </CalendarColumn>
            ))}
          </CalendarHeadRow>

          <CalendarHeadRow columns={datesInSelectedMonth.length + 1}>
            <CalendarColumn align="left">
              <CalendarCatTitle>FACTURÉ</CalendarCatTitle>
            </CalendarColumn>
            {datesInSelectedMonth.map((cellDate, i) => {
              return (
                <CalendarColumn
                  isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')}
                  key={'invoiceable' + cellDate.format('YYYY-MM-DD') + i}
                />
              )
            })}
          </CalendarHeadRow>

          {projectsShowing
            .filter((project) => project.isShowing && project.invoiceable === true)
            .map((project) => (
              <CalendarRow columns={datesInSelectedMonth.length + 1} key={project.name}>
                <CalendarColumn align="left">{`${project.emoji ?? ''} ${project.name}`}</CalendarColumn>
                {datesInSelectedMonth.map((cellDate) => (
                  <CalendarColumn
                    isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')}
                    key={project.name + cellDate.format('YYYY-MM-DD')}
                  >
                    <input
                      type="number"
                      defaultValue={getTimeCallback(project.notionId, cellDate)}
                      //value={getTimeCallback(project.projectId, cellDate)}
                      onChange={(e) => addTimeCallback(project.notionId, cellDate, parseInt(e.target.value))}
                    />
                  </CalendarColumn>
                ))}
              </CalendarRow>
            ))}

          <CalendarHeadRow columns={datesInSelectedMonth.length + 1}>
            <CalendarColumn align="left">
              <CalendarCatTitle>NON-FACTURÉ</CalendarCatTitle>
            </CalendarColumn>
            {datesInSelectedMonth.map((cellDate, i) => {
              return (
                <CalendarColumn
                  isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')}
                  key={'invoiceable' + cellDate.format('YYYY-MM-DD') + i}
                />
              )
            })}
          </CalendarHeadRow>

          {projectsShowing
            .filter((project) => project.invoiceable === false)
            .map((project) => (
              <CalendarRow columns={datesInSelectedMonth.length + 1} key={project.name}>
                <CalendarColumn align="left">{`${project.emoji ?? ''} ${project.name}`}</CalendarColumn>
                {datesInSelectedMonth.map((cellDate) => (
                  <CalendarColumn
                    isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')}
                    key={project.name + cellDate.format('YYYY-MM-DD')}
                  >
                    <input
                      type="number"
                      defaultValue={getTimeCallback(project.notionId, cellDate)}
                      //value={getTimeCallback(project.projectId, cellDate)}
                      onChange={(e) => addTimeCallback(project.notionId, cellDate, parseInt(e.target.value))}
                    />
                  </CalendarColumn>
                ))}
              </CalendarRow>
            ))}

          <CalendarHeadRow columns={datesInSelectedMonth.length + 1}>
            <CalendarColumn align="left">TOTAL</CalendarColumn>
            {datesInSelectedMonth.map((cellDate) => {
              const total = getTotal(cellDate)
              return (
                <CalendarColumn
                  isCurrentDay={dayjs().format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')}
                  isIncorrect={total !== 8}
                  key={'TOTAL' + cellDate.format('YYYY-MM-DD') + total}
                >
                  <div>{total}</div>
                </CalendarColumn>
              )
            })}
          </CalendarHeadRow>
        </CalendarGrid>
      )}
    </CalendarStyled>
  )
}
