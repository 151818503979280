import { LOGIN_ERROR, LOGIN_RESULT, LOGOUT } from 'pages/Login/Login.actions'

export interface AuthState {
  jwt?: string
  email?: string
}

const authDefaultState: AuthState = {
  jwt: undefined,
  email: undefined,
}

export function auth(state = authDefaultState, action: any): AuthState {
  switch (action.type) {
    case LOGOUT:
      return {
        jwt: undefined,
        email: undefined,
      }
    case LOGIN_RESULT: {
      return {
        jwt: action.jwt,
        email: action.email,
      }
    }
    case LOGIN_ERROR: {
      return {
        jwt: undefined,
        email: undefined,
      }
    }
    default:
      return state
  }
}
