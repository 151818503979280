import { Project } from 'shared/project/Project'
import { Client } from 'shared/client/Client'

import {
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_RESULT,
  GET_MY_TIMES_REQUEST,
  GET_MY_TIMES_RESULT,
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_RESULT,
} from '../pages/Projects/Projects.actions'
import { Time } from 'shared/time/Time'

export interface ProjectsState {
  projects: Project[]
  clients: Client[]
  myTimes: Time[]
}

const projectsDefaultState: ProjectsState = {
  projects: [],
  clients: [],
  myTimes: [],
}

export function projects(state = projectsDefaultState, action: any): ProjectsState {
  switch (action.type) {
    case GET_PROJECTS_REQUEST: {
      return {
        ...state,
        projects: [],
      }
    }
    case GET_PROJECTS_RESULT: {
      return {
        ...state,
        projects: action.projects,
      }
    }
    case GET_CLIENTS_REQUEST: {
      return {
        ...state,
        clients: [],
      }
    }
    case GET_CLIENTS_RESULT: {
      return {
        ...state,
        clients: action.clients,
      }
    }
    case GET_MY_TIMES_REQUEST: {
      return {
        ...state,
        myTimes: [],
      }
    }
    case GET_MY_TIMES_RESULT: {
      return {
        ...state,
        myTimes: action.myTimes,
      }
    }
    default:
      return state
  }
}
