import { useDispatch, useSelector } from 'react-redux'
import { ProjectsStyled } from './Projects.style'
import { State } from 'reducers'
import { useEffect } from 'react'
import { getProjects } from './Projects.actions'

export const Projects = () => {
  const dispatch = useDispatch()
  const projects = useSelector((state: State) => state.projects.projects)

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  return (
    <ProjectsStyled>
      <h1>All projects</h1>
      <div>
        To add or edit projects, please go to{' '}
        <a
          href="https://www.notion.so/smart-chain/41e522108b984d24b99e953121da9dab?v=50df9770a7c34c4a950934e5c0616b2d"
          target="_blank"
          rel="noreferrer"
        >
          🛠️ <u>Nos Sujets</u>
        </a>
      </div>
      <br />
      <div>
        {projects.map((project) => (
          <div>{`${project.emoji ?? ''} ${project.name}`}</div>
        ))}
      </div>
    </ProjectsStyled>
  )
}
