import { useSelector } from 'react-redux'

import { State } from 'reducers'
import { UserStyled } from './User.style'

export const User = () => {
  const email = useSelector((state: State) => state.auth.email)

  return (
    <UserStyled>
      <h1>My Profile</h1>
      <div>Logged in with {email}</div>
    </UserStyled>
  )
}
