import { LoginStyled, LoginLogo, LoginGoogle } from './Login.style'
import { GoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { LoginObject, login } from './Login.actions'
import { State } from 'reducers'

export const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state: State) => state.loading)

  const loginCallback = async (loginObject: LoginObject) => {
    dispatch(login({ navigate, loginObject }))
  }

  return (
    <LoginStyled>
      <div>
        <LoginLogo alt="Smartpod" src="/logo.svg" />
        <LoginGoogle>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              loginCallback(credentialResponse as LoginObject)
            }}
            onError={() => {
              console.log('Login Failed')
            }}
            theme="filled_blue"
          />
        </LoginGoogle>
      </div>
    </LoginStyled>
  )
}
