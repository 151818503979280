import { Calendar } from 'pages/Calendar/Calendar.view'
import { Login } from 'pages/Login/Login.view'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { Menu } from './App.components/Menu/Menu.controller'
import { Toaster } from './App.components/Toaster/Toaster.controller'
import { configureStore } from './App.store'
import { AppContainer } from './App.style'
import { ProtectedRoute } from './App.components/ProtectedRoute/ProtectedRoute'
import { User } from 'pages/User/User.view'
import { Projects } from 'pages/Projects/Projects.view'
import { Clients } from 'pages/Clients/Clients.view'

export const store = configureStore({})

export const App = () => {
  return (
    <GoogleOAuthProvider clientId="151959459885-tpq4kfef2nlo8m99tp25lk8hkpc04k2s.apps.googleusercontent.com">
      <Provider store={store}>
        <BrowserRouter>
          <AppContainer>
            <Routes>
              <Route path="/" element={<Login />} />

              <Route
                path="/calendar"
                element={
                  <ProtectedRoute>
                    <Menu />
                    <Calendar />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/user"
                element={
                  <ProtectedRoute>
                    <Menu />
                    <User />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/projects"
                element={
                  <ProtectedRoute>
                    <Menu />
                    <Projects />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/clients"
                element={
                  <ProtectedRoute>
                    <Menu />
                    <Clients />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </AppContainer>
          <Toaster />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  )
}
